jQuery(document).ready(function () {
  jQuery("#review_slider").slick({
    draggable: true,
    autoplay: false,
    arrows: false,
    dots: true,
    fade: true,
    cssEase: "ease-in-out",
  });
  jQuery("#usp-section-inner-olds").slick({
    accessibility: true,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    touchThreshold: 20,
    rtl: theme.config.rtl,
    slidesToShow: 1,
  });
  jQuery("#usp-section-inner").slick({
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    arrows: false,

    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  });

  jQuery("#product-delivery-info").slick({
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    arrows: false,

    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
          dots: true,
        },
      },
    ],
  });

  jQuery("#checkout-upsells").slick({
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    arrows: false,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
        },
      },
    ],
  });

  jQuery("#smarte-collection-nav").slick({
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    arrows: false,

    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: false,
        },
      },
    ],
  });
});
